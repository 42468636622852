import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  fi: {
    translation: {
      heading: 'Väylävirasto - Aineiston välitysalusta',
      loading_content: 'Ladataan sisältöä...',
      download_file: 'Lataa',
      download_header: 'Lataa',
      tags_header: 'Tägit',
      showing: 'Näytetään',
      name: 'Nimi',
      last_modified: 'Viimeksi muokattu',
      size: 'Koko',
      show_more: 'Näytä lisää',
      homepage: 'Etusivu',
      contact_info: 'Asiointi',
      telephone_switch: 'Vaihde',
      fax: 'Faksi',
      pasila_customer_service: 'Pasilan aulapalvelu',
      kirjaamo_email: 'kirjaamo@vayla.fi',
      postal_address: 'Postiosoite',
      ftia_full: 'Väylävirasto',
      ftia_po_box: 'PL 33',
      ftia_zip_country: '00521 HELSINKI',
      accessibility: 'Saavutettavuus',
      accessibility_url: 'http://vayla.fi/tietoa-meista/yhteystiedot/saavutettavuus/saavutettavuusseloste-ava-palju',
      about_the_site: 'Tietoa sivustosta',
      data_protection: 'Tietosuoja',
      social_media: 'Sosiaalinen media',
      external_link: 'Tämä on ulkoinen linkki',
      footer_paragraph:
        'Väylävirasto vastaa valtion tieverkon, rautateiden ja vesiväylien kehittämisestä sekä kunnossapidosta. Näin edistämme yhteiskunnan hyvinvointia ja elinkeinoelämän kilpailukykyä.',
      go_to_content: 'Siirry sisältöön',
      go_to_search_result: 'Siirry hakutulokseen',
      go_to_footer: 'Siirry footeriin',
      go_to_header: 'Siirry headeriin',
      search_results_updated: 'Hakutulokset päivitetty',
      go_to_filters: 'Siirry filttereihin',
      go_back_to_home: 'Palaa takaisin etusivulle',
      instruction_list: 'Ohjeluettelo',
      instructions_help:
        'Hae haluamasi ohje tai määräys käyttämällä taulukon sisällön rajaustoimintoja.',
      railways: 'Rautatiet',
      roads: 'Tiet',
      waterways: 'Vesiväylät',
      skill_structures: 'Taitorakenteet',
      other: 'Muut',
      filter_help:
        'Valitse tyyppi filtterin arvoja yksi kerrallaan. Valitut arvot lisätään omaan listaansa Valitse tyyppi -valikon jälkeen. Kun yksi tai useampia tyyppejä on valittuna, vain näihin tyyppeihin kuuluviar ivejä esitetään hakutuloksessa.',
      select_type: 'Valitse tyyppi',
      valid_only: 'Vain voimassa olevat',
      filter_results: 'Rajaa hakutuloksia',
      search: 'Kirjoita hakusana',
      topic: 'Aihe',
      type: 'Tyyppi',
      identifier: 'Tunnus',
      date: 'PVM',
      contact_person: 'Yhteyshenkilö',
      valid: 'Voimassa',
      updated: 'Päivitetty',
      file: 'Tiedosto',
      about_open_data: 'Lisätietoja avoimesta datasta',
      about_open_data_url: 'http://vayla.fi/avoindata',
      about_open_contact_text: 'Yhteystiedot',
      about_open_contact_email: 'paikkatieto@vayla.fi',
      back: 'Takaisin',
      folder: 'Hakemisto',
      license_prefix: 'Tämä teos on lisensoitu',
      license_postfix: 'Creative Commons Nimeä 4.0 Kansainvälinen -lisenssillä'
    }
  },
  en: {
    translation: {
      heading: 'Finnish Transport Infrastructure Agency - Forwarding service for open data',
      loading_content: 'Loading content...',
      tags_header: 'Tags',
      showing: 'Showing',
      name: 'Name',
      last_modified: 'Last modified',
      size: 'Size',
      show_more: 'Show more',
      download_file: 'Download',
      download_header: 'Download',
      homepage: 'Home',
      contact_info: 'Contact Information',
      telephone_switch: 'Telephone switchboard',
      fax: 'Fax',
      pasila_customer_service: 'Customer service in Pasila',
      kirjaamo_email: 'ftia@ftia.fi',
      postal_address: 'Postal address',
      ftia_full: 'Finnish Transport Infrastructure Agency',
      ftia_po_box: 'P.O. Box 33',
      ftia_zip_country: '00521 HELSINKI',
      accessibility: 'Accessibility',
      accessibility_url: 'http://vayla.fi/en/about/contact-information/accessibility/accessibility-statement-ava-palju',
      about_the_site: 'About the site',
      data_protection: 'Data protection',
      social_media: 'Social media',
      external_link: 'This is an external link',
      footer_paragraph:
        'The Finnish Transport Infrastructure Agency is responsible for developing and maintaining the state-owned road network, the railways and the waterways. Through our tasks, which include maintaining the level of service of transport, we promote wellbeing in society and competitiveness of Finnish industry.',
      go_to_content: 'Go to content',
      go_to_search_result: 'Go to search result',
      go_to_footer: 'Go to footer',
      go_to_header: 'Go to header',
      search_results_updated: 'Search results updated',
      go_to_filters: 'Go to filters',
      go_back_to_home: 'Return back to homepage',
      instruction_list: 'Instructions list',
      instructions_help:
        'Use the table filters to find the instruction or order you want.',
      railways: 'Railways',
      roads: 'Roads',
      waterways: 'Waterways',
      skill_structures: 'Skill structures',
      other: 'Other',
      filter_help:
        'Select the type of filter values ​​one at a time. The selected values ​​are added to their own list after the Select Type menu. When one or more types are selected, only rows belonging to those types are displayed in the search result.',
      select_type: 'Select type',
      valid_only: 'Valid only',
      filter_results: 'Filter results',
      search: 'Search...',
      topic: 'Topic',
      type: 'Type',
      identifier: 'Identifier',
      date: 'Date',
      contact_person: 'Contact person',
      valid: 'Valid',
      updated: 'Updated',
      file: 'File',
      about_open_data: 'More about open data',
      about_open_data_url:
        'https://vayla.fi/en/transport-network/data/open-data',
      about_open_contact_text: 'Contact information',
      about_open_contact_email: 'paikkatieto@vayla.fi',
      back: 'Go back',
      folder: 'Folder',
      license_prefix: 'This work is licensed under a',
      license_postfix: 'Creative Commons Attribution 4.0 International License'
    }
  },
  sv: {
    translation: {
      heading: 'Trafikledsverket - Förmedlingstjänst för öppna data',
      loading_content: 'Innehållet laddas',
      tags_header: 'Taggar',
      showing: 'Som visar',
      name: 'Namn',
      last_modified: 'Senast ändrad',
      size: 'Storlek',
      download_file: 'Ladda ner',
      download_header: 'Ladda ner',
      show_more: 'Visa mer',
      homepage: 'Hem',
      contact_info: 'Kontaktuppgifter',
      telephone_switch: 'Telefonväxel',
      fax: 'Fax',
      pasila_customer_service: 'Kundservice i Böle',
      kirjaamo_email: 'trafikledsverket@trafikledsverket.fi',
      postal_address: 'Postadress',
      ftia_full: 'Trafikledsverket',
      ftia_po_box: 'PB 33',
      ftia_zip_country: '00521 HELSINGFORS',
      accessibility: 'Tillgänglighet',
      accessibility_url: 'http://vayla.fi/sv/trafikledsverket/kontaktuppgifter/tillganglighet/tillganglighetsutlatande-ava-palju',
      about_the_site: 'Om webbplatsen',
      data_protection: 'Dataskyddspolicy',
      social_media: 'Sociala medier',
      external_link: 'Detta är en extern webbadress',
      footer_paragraph:
        'Trafikledsverket ansvarar för att utveckla och underhålla det statliga vägnätet, järnvägarna och farlederna. Därigenom främjar vi vårt samhälles välmående och näringslivets konkurrenskraft.',
      go_to_content: 'Gå till innehåll',
      go_to_search_result: 'Gå till sökresultatet',
      go_to_footer: 'Gå till sidfoten',
      go_to_header: 'Gå till header',
      search_results_updated: 'Sökresultaten har uppdaterats',
      go_to_filters: 'Gå till filtren',
      go_back_to_home: 'Återgå till förstasidan',
      instruction_list: 'Instruktionslista',
      instructions_help:
        'Använd tabellens beskärningsfunktioner för att hitta den instruktion eller ordning du vill ha.',
      railways: 'Järnväg',
      roads: 'Väg',
      waterways: 'Vattenväg',
      skill_structures: 'Färdighetsstruktur',
      other: 'Övrig',
      filter_help:
        'Välj typ av filtervärden ett i taget. De valda värdena läggs till i deras egen lista efter menyn Välj typ. När en eller flera typer väljs visas endast rader som tillhör dessa typer i sökresultatet.',
      select_type: 'Välj typ',
      valid_only: 'Gäller endast',
      filter_results: 'Begränsa dina sökresultat',
      search: 'Skriv ett sökord',
      topic: 'Ämne',
      type: 'Typ',
      identifier: 'Märka',
      date: 'Datum',
      contact_person: 'Kontaktperson',
      valid: 'Giltig',
      updated: 'Uppdaterad',
      file: 'Fil',
      about_open_data: 'Läs mer om öppen data',
      about_open_data_url:
        'https://vayla.fi/sv/trafikleder/material/oppna-data',
      about_open_contact_text: 'Kontaktinformation',
      about_open_contact_email: 'paikkatieto@vayla.fi',
      back: 'Gå tillbaka',
      folder: 'Mapp',
      license_prefix: 'Detta verk är licensierat under en',
      license_postfix: 'Creative Commons Erkännande 4.0 Internationell Licens'
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'fi', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  debug: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;